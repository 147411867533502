import React from 'react';
import {Grid, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

const Navigation = () => {
    const navigate = useNavigate();

    return (
        <Grid
            container
            sx={{
                position: 'fixed',
                height: '100vh',
                width: '200px',
                top: 0,
                left: 0,
                bgcolor: 'rgba(255,255,255,0.8)',
                zIndex: 3,
            }}
            alignItems={"flex-start"}
            justifyContent={"center"}
        >
            <Grid
                item
                sx={{
                    height: 200,
                    py: 4
                }}
            >
                <Box
                    component="img"
                    src="/photos/logo4.png"
                    alt="buoy"
                    sx={{width: 150}}
                />
                <List
                    sx={{
                        py: 12
                    }}
                >
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/home')}>
                            <ListItemText primary="Home"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/about')}>
                            <ListItemText primary="About"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/services')}>
                            <ListItemText primary="Services"/>
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/gallery')}>
                            <ListItemText primary="Gallery"/>
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/faq')}>
                            <ListItemText primary="FAQ"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/contact')}>
                            <ListItemText primary="Contact Us"/>
                        </ListItemButton>
                    </ListItem>
                </List>

            </Grid>
            <Grid
                item
            >

            </Grid>
        </Grid>
    )
};

export default Navigation;