import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {Grid} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import {Menu} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

const rightLink = {
  fontSize: 16,
  color: 'common.white',
  ml: 3,
};

function AppAppBar({setMobileNavOpen, mobileNavOpen}) {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between', pt: 1 }}>
          <Typography
            variant="h6"
            color="inherit"
            sx={{ fontSize: 24 }}
          >
            {'eye in the sky'}
          </Typography>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                  onClick={() => setMobileNavOpen(!mobileNavOpen)}
              >
                <Grid
                    container
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                <Menu sx={{color: "#ffffff"}} />
                <Typography variant={"subtitle2"} sx={{color: "#ffffff"}}>
                  Menu
                </Typography>
                </Grid>
              </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
