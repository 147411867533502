import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';

function ServicesView() {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container component="section" sx={{mt: 1, display: 'flex'}}>
            <Grid container>
                <Grid item xs={12} md={12} sx={{zIndex: 1}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit}>
                            <Typography variant="h2" component="h2" gutterBottom  marked="center" sx={{textAlign: 'center', mb: 4}}>
                                Services
                            </Typography>
                            <Grid sx={{border: '3px solid black', borderRadius: '4px', p: 3, mb: 4}}>
                                <img
                                    src="/photos/real estate 1.jpeg"
                                    alt="real estate"
                                    style={{
                                        float: 'left',
                                        margin: '5px',
                                        marginRight: '20px',
                                        width: '200px',
                                    }}
                                />
                                <Typography variant={'h4'} sx={{mb: 1}}>
                                    Real Estate
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    For many home listings drone photography has become a standard practice. Potential clients get
                                    a much better idea of the property they are looking at when they get to see an aerial view. You 
                                    will receive 2-3 varying angles of the property and each of them will include 3 varying levels
                                    of light levels for you to choose from. If there is something specific you are looking for let
                                    me know and I will be happy to get it for you. I can also do some mild editing for you. Maybe the driveway
                                    has numerous tire marks and needs a wash. I can digitally make those less apparent. Videography is also available
                                    for an extra fee. This fee is hard to give an estimate on without knowing what you are looking for. Provide details
                                    in the contact section and I will get back to you with a quote.
                                    <p>In general pricing for the real estate photography services listed above in the Meridian area is $100. I do charge $20 more
                                    for the immediate area outside of Meridian. Boise, Nampa, Caldwell for example. Further travel may incur
                                    aditional charges. Edits also incur an extra charge depending on the amount of editing needed.</p>
                                    <p><b>Real estate agents</b>, your 1st project will be free. After that I hope you're satisfied with the work and will use EITSP
                                    in the future! </p>
                                    
                                </Typography>
                            </Grid>
                            <Grid sx={{border: '3px solid black', borderRadius: '4px', p: 3, mb: 4}}>
                                <img
                                    src="/photos/park1.jpg"
                                    alt="park1"
                                    style={{
                                        float: 'left',
                                        margin: '5px',
                                        marginRight: '20px',
                                        width: '200px',
                                    }}
                                />
                                <Typography variant={'h4'} sx={{mb: 1}}>
                                    Inspection and Surveying
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    I can assist you with any general photography or videography that you need. Construction sites, agriculture,
                                    large towers, etc... Anything that you need I can assist you with. 
                                    <p>Pricing for these services varies greatly. There are many factors to take into consideration. Use the
                                    contact us form and describe your needs, be as specific as you can. Include things like when, where, what you want, and anything else you think might be relevant. I'll get back with you shortly
                                    and give you an estimate. 
                                    </p>
                                </Typography>
                            </Grid>
                            <Grid sx={{border: '3px solid black', borderRadius: '4px', p: 3, mb: 4}}>
                                <img
                                    src="/photos/water 1.JPG"
                                    alt="Water1"
                                    style={{
                                        float: 'left',
                                        margin: '5px',
                                        marginRight: '20px',
                                        width: '200px',
                                    }}
                                />
                                <Typography variant={'h4'} sx={{mb: 1}}>
                                    Special Events
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    Drone photography or videography can be great for many types of events. Weddings, church events, family
                                    gatherings, and many more! I can get photo and/or video depending on your needs. Eye in the Sky does not offer 
                                    traditional photography services. If that's what you are looking for you'll want to secure that as well from 
                                    another source.
                                    <p>Pricing for these services varies greatly. There are many factors to take into consideration. Use the
                                    contact us form and describe your needs, be as specific as you can. Include things like when, where, what you want, and anything else you think might be relevant. I'll get back with you shortly 
                                    and give you an estimate. </p>
                                </Typography>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="We will send you our best offers, once a week."
            />
        </Container>
    );
}

export default ServicesView;
