import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';
import {firestore} from "../../firebase/firebaseConfig";
import axios from "axios";

function ProductCTA() {
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({})

    const updateInput = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        await sendEmail()
        setFormData({
            name: '',
            email: '',
            message: '',
        })
    }

    const sendEmail = () => {
        console.log(formData);
        axios({
            method: 'post',
            url: 'https://us-central1-eyeinthesky-de903.cloudfunctions.net/submit/',
            data: {
                ...formData,
            }
        }).then(res => {
            firestore.collection('emails').add({
                name: formData.name,
                email: formData.email,
                message: formData.message,
                time: new Date(),
            }).then(r => console.log(r))
        })
            .catch(error => {
                console.log(error)
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container component="section" sx={{mt: 1, display: 'flex'}}>
            <Grid container>
                <Grid item xs={12} md={12} sx={{zIndex: 1}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box sx={{width: '100%'}}>
                            <Typography variant="h2" component="h2" gutterBottom marked="center"
                                        sx={{textAlign: 'center', mb: 4}}>
                                Contact Us
                            </Typography>
                            <Container component="section" sx={{mt: 12, display: 'flex'}}>
                                <Grid container>
                                    <Grid item xs={12} md={6} sx={{zIndex: 1}}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                bgcolor: 'warning.main',
                                                py: 8,
                                                px: 3,
                                            }}
                                        >
                                            <Box component="form" onSubmit={handleSubmit} sx={{maxWidth: 400}}>
                                                <TextField
                                                    noBorder
                                                    placeholder="Name"
                                                    name={'name'}
                                                    variant="standard"
                                                    onChange={updateInput}
                                                    value={formData.name || ''}
                                                    sx={{width: '100%', mt: 1, mb: 1}}
                                                />
                                                <TextField
                                                    noBorder
                                                    placeholder="Email"
                                                    name={'email'}
                                                    variant="standard"
                                                    onChange={updateInput}
                                                    value={formData.email || ''}
                                                    sx={{width: '100%', mt: 1, mb: 1}}
                                                />
                                                <TextField
                                                    noBorder
                                                    placeholder="Questions/Comments"
                                                    variant="standard"
                                                    name={'message'}
                                                    onChange={updateInput}
                                                    value={formData.message || ''}
                                                    multiline
                                                    rows={4}
                                                    sx={{width: '100%', mt: 1, mb: 2}}
                                                />
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    sx={{width: '100%'}}
                                                >
                                                    Request Information
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        sx={{display: {md: 'block', xs: 'none'}, position: 'relative'}}
                                    >
                                        <Box
                                            component="img"
                                            src="/photos/image6.jpg"
                                            alt="call to action"
                                            sx={{
                                                position: 'absolute',
                                                top: -28,
                                                left: -28,
                                                right: 0,
                                                bottom: 0,
                                                width: '100%',
                                                maxWidth: 600,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Snackbar
                                    open={open}
                                    closeFunc={handleClose}
                                    message="Successfully sent information request!"
                                />
                            </Container>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="We will send you our best offers, once a week."
            />
        </Container>
    );
}

export default ProductCTA;
