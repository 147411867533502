import {CssBaseline, Grid} from "@mui/material";
import React, {Fragment, useState} from "react";
import theme from "./modules/theme";
import {ThemeProvider} from "@mui/material/styles";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Navigation from './modules/Navigation';
import MobileNavigation from "./modules/MobileNavigation";
import Home from "./pages/Home";
import About from './pages/About';
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import FAQ from "./pages/FAQ";
import ContactUs from "./pages/ContactUs";
import {Hidden} from "@mui/material";
import AppAppBar from "./modules/views/AppAppBar";

const App = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline/>
            <Hidden lgUp>
                <AppAppBar
                    setMobileNavOpen={setMobileNavOpen}
                    mobileNavOpen={mobileNavOpen}
                />
                {
                    mobileNavOpen &&
                    <MobileNavigation
                        setMobileNavOpen={setMobileNavOpen}
                    />
                }
            </Hidden>
            <Hidden smDown>
                <Navigation/>
            </Hidden>
            <Routes>
                <Route path="/home" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/services" element={<Services/>}/>
                <Route path="/gallery" element={<Gallery/>}/>
                <Route path="/faq" element={<FAQ/>}/>
                <Route path="/contact" element={<ContactUs/>}/>
                <Route path="*" element={<Navigate to={'/home'} replace/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
