import React from 'react';
import {Grid, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

const Navigation = ({setMobileNavOpen}) => {
    const navigate = useNavigate();

    return (
        <Grid
            container
            sx={{
                position: 'fixed',
                height: '100vh',
                width: '200px',
                top: 0,
                left: 0,
                bgcolor: 'rgba(255,255,255,0.8)',
                zIndex: 3,
            }}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Grid
                item
                sx={{
                    height: 200,
                    py: 4,
                    pl: 2
                }}
            >
                <List
                    sx={{
                        py: 8
                    }}
                >
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            navigate('/home');
                            setMobileNavOpen(false);
                        }}>
                            <ListItemText primary="Home"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            navigate('/about');
                            setMobileNavOpen(false);
                        }}>
                            <ListItemText primary="About"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            navigate('/services');
                            setMobileNavOpen(false);
                        }}>
                            <ListItemText primary="Services"/>
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            navigate('/gallery');
                            setMobileNavOpen(false);
                        }}>
                            <ListItemText primary="Gallery"/>
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            navigate('/faq');
                            setMobileNavOpen(false);
                        }}>
                            <ListItemText primary="FAQ"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            navigate('/contact');
                            setMobileNavOpen(false);
                        }}>
                            <ListItemText primary="Contact Us"/>
                        </ListItemButton>
                    </ListItem>
                </List>

            </Grid>
            <Grid
                item
            >

            </Grid>
        </Grid>
    )
};

export default Navigation;