import * as React from 'react';
import ProductCategories from '../modules/views/ProductCategories';
import ProductSmokingHero from '../modules/views/ProductSmokingHero';
import AppFooter from '../modules/views/AppFooter';
import ProductHero from '../modules/views/ProductHero';
import ProductValues from '../modules/views/ProductValues';
import ProductCTA from '../modules/views/ProductCTA';

function Home() {
    return (
        <React.Fragment>
            <ProductHero/>
            <ProductValues/>
            <ProductCTA/>
            <ProductSmokingHero/>
            <AppFooter/>
        </React.Fragment>
    );
}

export default Home;
