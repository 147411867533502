import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';

function ProductCTA() {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const images = [
        '/photos/image1.jpg',
        '/photos/image2.jpg',
        '/photos/image3.jpg',
        '/photos/image4.jpg',
        '/photos/image5.jpg',
        '/photos/image6.jpg',
        '/photos/image7.jpg',
    ];

    return (
        <Container component="section" sx={{mt: 1, display: 'flex'}}>
            <Grid container>
                <Grid item xs={12} md={12} sx={{zIndex: 1}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit}>
                            <Typography variant="h2" component="h2" gutterBottom marked="center"
                                        sx={{textAlign: 'center', mb: 4}}>
                                Gallery
                            </Typography>
                            <Grid
                                container
                            >
                                {
                                    images.map((image) => (
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <img
                                                src={image}
                                                style={{
                                                    margin: '5px',
                                                    marginRight: '20px',
                                                    width: '100%',
                                                    border: '3px solid black',
                                                    borderRadius: '4px',
                                                    p: 3,
                                                    mb: 4
                                                }}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="We will send you our best offers, once a week."
            />
        </Container>
    );
}

export default ProductCTA;
