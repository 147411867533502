import * as React from 'react';
import ProductSmokingHero from '../modules/views/ProductSmokingHero';
import AppFooter from '../modules/views/AppFooter';
import FAQView from '../modules/views/FAQView';
import {Grid} from "@mui/material";

function FAQ() {
    return (
        <React.Fragment>
            <Grid
                container
                direction={"column"}
                sx={{
                    minHeight: '100vh'
                }}
            >
                <FAQView/>
                <Grid
                    item
                    xs
                >

                </Grid>
                <ProductSmokingHero/>
                <AppFooter/>
            </Grid>
        </React.Fragment>
    );
}

export default FAQ;
