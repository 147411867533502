import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';

function ProductCTA() {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container component="section" sx={{mt: 1, display: 'flex'}}>
            <Grid container>
                <Grid item xs={12} md={12} sx={{zIndex: 1}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit}>
                            <Typography variant="h2" component="h2" gutterBottom  marked="center" sx={{textAlign: 'center', mb: 4}}>
                                About Us
                            </Typography>
                            <Grid sx={{border: '3px solid black', borderRadius: '4px', p: 3, mb: 4}}>
                                <img
                                    src="/photos/image1.jpg"
                                    alt="Longtail boat in Thailand"
                                    style={{
                                        float: 'left',
                                        margin: '5px',
                                        marginRight: '20px',
                                        width: '200px',
                                    }}
                                />
                                <Typography variant={'subtitle1'}>
                                    Hello! My name is Austin Switzer and I am the owner of Eye In The Sky Photos. I have been flying drones 
                                    as a hobbyist since 2012 and in 2022 decided to get my FAA drone license, AKA Part 107. Drones have changed a lot
                                    since then and the things you can do with them are quite amazing. Drones are tons of fun, but for my full time job
                                    I am a music teacher
                                    in the area. Although some might think that makes me not focus on this business, I think of it in the opposite way.
                                    I do this because I genuinely enjoy it and want to do a great 
                                    job. I don't do this solely because of a pay check. I enjoy it so much that I wanted to do it even more in my free 
                                    time and decided this was a great way I could do that. 
                                </Typography>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="We will send you our best offers, once a week."
            />
        </Container>
    );
}

export default ProductCTA;
