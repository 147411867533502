import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';

function FAQView() {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const faqs = [
        {
            question: 'Since you are a teacher, do you have time to do my event?',
            answer: 'It is true that outside of summer I sometimes turn down events. I want to do a great job for whatever project you need and I will let you know upfront if I will be able to accommodate your request or not. I will never accept a job that I do not have the time to do.',
        },
        {
            question: "I have a drone. Can't I just take the photos myself?",
            answer: 'According to the law, if you are using a drone for any sort of commercial purposes you must be licensed from the FAA. For real estate you would need that license but if you wanted to record the wedding of a friend without getting paid, that would be within the law.',
        },
        {
            question: 'Are you licensed?',
            answer: 'Yes I have my small unmanned aerial system license, AKA Part 107, from the FAA. This is required for any commercial projects, even if the pilot is not the one receiving compensation. Although I do not post it here for privacy reasons, I would be happy to present it on the condition of a job.',
        },
        {
            question: 'Are you insured?',
            answer: 'Yes! EITSP has a 1 million dollar insurance policy. Any experienced drone pilot will tell you that although rare, serious accidents can happen. Sometimes through no fault of the remote pilot at all. If a serious accident happens the drone operator might not be the only person held liable in civil court. The hiring party could also be in jeopardy. Do not risk it. Even if you choose another company for your drone services make sure they have insurance! It is not worth the risk. Although I do not post proof of insurance here for privacy reasons, I would be happy to supply it upon request on the condition of a job.',
        },
        {
            question: 'Do I need authorization for you to fly the drone?',
            answer: 'Depending on the area, the FAA may require authorization to fly there. EITSP will take care of any authorizations needed from the FAA. That will be included in all price estimates.',
        },
        {
            question: 'Can I fly your drone to do my inspection?',
            answer: 'The FAA does allow other individuals to fly a drone for commercial purposes as long as a certified pilot is directly supervising. With that said, in general I typically decline these requests for safety reasons. Sometimes it might be possible depending on the area. If it is in a secluded area with no one around and no serious risk to person or property I might accommodate this request. If it is downtown Boise near people then I would have to decline for safety reasons. ',
        },
        {
            question: 'What kind of drone will you use for my project?',
            answer: 'This somewhat depends on the job. I have flown drones from DJI, Autel, and Skydio. My current favorite drone for most projects is the newly released DJI Mini 3 Pro. This drone has so many pros and almost no cons. It is very quiet and compact. This means that I can do jobs while attracting as little attention as possible. No one wants to hear a sound that they might think is a swarm of bees over their house or special event. The camera on it is as good as you could possibly need and it does a great job. The only time a different drone might be used would be if I needed to fly for a very extended amount of time or if the wind was very extreme. In that case the Mini 3 substitute is the DJI Mavic 3. The Mavic is the flagship of DJI but frankly outside of flight time or wind resistance it offers nothing that the Mini 3 can not do.',
        },
        {
            question: 'What forms of payment do you accept?',
            answer: 'For your convenience EITSP accepts all major credit cards. Payments are processed through Square Up. In addition EITSP has a venmo business account, @eyeintheskyphotos , so we can take payments that way. There are no fees on the customer end for either of the previous payment methods. A good old fashioned check made out to "Austin Switzer" with "EITSP" in the notes section is also accepted.',
        },{
            question: 'When will I receive my project?',
            answer: 'Real estate requests are usually submitted back to you within 2 business days, however a faster timeline might be possible if requested. Other services are too specific to provide a generic timeline. If you fill out the contact us form though, I can give you an estimated time to complete.',
        },
        {
            question: 'How will I receive my project',
            answer: 'EITSP uses google workspace, sometimes referred to as google business, to manage all files. It is just like a google drive folder. It is very simple to use. You will receive a link via email that will have the relevant file(s) for your project. All files will be available digitally for one year from the date you receive them. In that window you will want to make sure you have downloaded all files you wish to keep.',
        },
        {
            question: 'Is payment required before a project?',
            answer: 'For real estate projects all files will have a watermark over any images/videos. The watermark(s) will be removed after EITSP receives full payment. This gives you a chance to preview the project to make sure you are satisfied with it. For all other projects EITSP typically requires a 50% deposit before doing the job. After which you will receive your watermarked project. Once you preview it and are satisfied the watermark will be removed after the remaining balance is received by EITSP.',
        }
    ];

    return (
        <Container component="section" sx={{mt: 1, display: 'flex'}}>
            <Grid container>
                <Grid item xs={12} md={12} sx={{zIndex: 1}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            py: 8,
                            px: 3,
                        }}
                    >
                        <Box component="form" onSubmit={handleSubmit} sx={{width: '100%'}}>
                            <Typography variant="h2" component="h2" gutterBottom  marked="center" sx={{textAlign: 'center', mb: 4}}>
                                FAQ
                            </Typography>
                            {
                                faqs.map((faq) => (
                                    <Grid sx={{border: '3px solid black', borderRadius: '4px', p: 3, mb: 4, width: '100%'}}>
                                        <Typography variant={'subtitle1'} sx={{mb: 1, fontWeight: 'bold'}}>
                                            Q: {faq.question}
                                        </Typography>
                                        <Typography variant={'subtitle2'}>
                                            A: {faq.answer}
                                        </Typography>
                                    </Grid>
                                ))
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="We will send you our best offers, once a week."
            />
        </Container>
    );
}

export default FAQView;
