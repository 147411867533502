import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 8, mb: 12, display: 'flex', position: 'relative', zIndex: 2 }}>
        <Box
          component="img"
          src="/photos/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180, zIndex: 0 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/photos/real estate 1.jpeg"
                alt="suitcase"
                sx={{ height: 200, position: 'relative', zIndex: 2 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Real Estate
              </Typography>
              <Typography variant="h5">
                {
                  "Drone photography has exploded in the real estate market. Don't miss out! "
                }

                {
                  ' Studies have shown that for many types of properties a drone photo significantly increases in person tours of the property.'
                }{
                  ' See the services page for pricing or contact us for more details or to schedule your job.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                  component="img"
                  src="/photos/park1.jpg"
                  alt="special events"
                  sx={{ height: 200, position: 'relative', zIndex: 2 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Inspections and Surveying
              </Typography>
              <Typography variant="h5">
                {
                  'We can help with any surveying or inspections you may need. From agriculture to construction a drone'
                }

                {
                  ' can be a great tool for you to use. Fill out our contact form for more information.'
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                  component="img"
                  src="/photos/water 1.JPG"
                  alt="water"
                  sx={{ height: 200, position: 'relative', zIndex: 2 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Special Events
              </Typography>
              <Typography variant="h5">
                {
                  'If you have a special event coming up, drone photography can be a great way to remember'
                }

                {
                  ' the occasion! We can customize the experience in any way you want. Contact us for more information.'
                }
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
