import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

export default function AppFooter() {
    return (
        <Typography
            component="footer"
            sx={{display: 'flex', bgcolor: 'secondary.light'}}
        >
            <Container sx={{my: 2, display: 'flex'}}>
                <Grid container spacing={5}>
                    <Grid item>
                        {'© '}
                            Eye In The Sky Photos
                        {' '}
                        {new Date().getFullYear()}
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}
