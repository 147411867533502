import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 12 }}
    >
      <Box
        component="img"
        src="/photos/logo1.jpg"
        alt="buoy"
        sx={{ width: 150 }}
      />
    </Container>
  );
}

export default ProductSmokingHero;
